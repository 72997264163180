@import url("https://fonts.googleapis.com/css?family=Roboto:200,300,400,400italic,500,500italic,700,700italic,900&subset=latin,cyrillic-ext,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext");

html {
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100vh;
  background:  #303030;

}

#root {
  display: flex;
  flex: 1;
}
::-webkit-scrollbar {display:none;}

body {
  display: flex;
  flex: 1 1;
  margin: 0;
  color: white;
  font-family: Roboto,Helvetica,Arial,sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
