@import url(https://fonts.googleapis.com/css?family=Roboto:200,300,400,400italic,500,500italic,700,700italic,900&subset=latin,cyrillic-ext,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext);
html {
  display: flex;
  flex: 1 1;
  width: 100vw;
  height: 100vh;
  background:  #303030;

}

#root {
  display: flex;
  flex: 1 1;
}
::-webkit-scrollbar {display:none;}

body {
  display: flex;
  flex: 1 1;
  margin: 0;
  color: white;
  font-family: Roboto,Helvetica,Arial,sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

